import { ThemeProvider, PumpkinLanding } from "./pumpkin-components"
import "./App.css"

function App() {
  return (
    <ThemeProvider>
      <PumpkinLanding />
    </ThemeProvider>
  )
}

export default App

