"use client"

import { useState, useEffect, createContext, useContext } from "react"
import {
  FaTwitter,
  FaTelegram,
  FaFileAlt,
  FaMoon,
  FaSun,
  FaExchangeAlt,
  FaChartLine,
  FaShieldAlt,
  FaRocket,
  FaClock,
} from "react-icons/fa"
import "./pumpkin-styles.css"

// Create a simple theme context
const ThemeContext = createContext({
  theme: "dark",
  toggleTheme: () => {},
})

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState("dark")

  useEffect(() => {
    // Check for stored theme preference
    const storedTheme = localStorage.getItem("theme")
    if (storedTheme) {
      setTheme(storedTheme)
    } else if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
      // Use system preference as fallback
      setTheme("dark")
    }
  }, [])

  useEffect(() => {
    // Update localStorage and document class when theme changes
    localStorage.setItem("theme", theme)
    document.documentElement.className = theme
  }, [theme])

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light")
  }

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>
}

// Custom hook to use the theme
function useTheme() {
  return useContext(ThemeContext)
}

// Button component
function Button({ children, variant = "default", className = "", onClick, asChild, href, target, rel }) {
  const classes = `btn btn-${variant} ${className}`

  if (asChild && href) {
    return (
      <a href={href} className={classes} target={target} rel={rel} onClick={onClick}>
        {children}
      </a>
    )
  }

  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  )
}

// Badge component
function Badge({ children, variant = "default", className = "" }) {
  return <span className={`badge badge-${variant} ${className}`}>{children}</span>
}

// Card components
function Card({ children, className = "" }) {
  return <div className={`card ${className}`}>{children}</div>
}

function CardHeader({ children }) {
  return <div className="card-header">{children}</div>
}

function CardTitle({ children, className = "" }) {
  return <h3 className={`card-title ${className}`}>{children}</h3>
}

function CardDescription({ children }) {
  return <p className="card-description">{children}</p>
}

function CardContent({ children }) {
  return <div className="card-content">{children}</div>
}

function CardFooter({ children, className = "" }) {
  return <div className={`card-footer ${className}`}>{children}</div>
}

// Main component
export function PumpkinLanding() {
  const { theme, toggleTheme } = useTheme()
  const [isNavOpen, setIsNavOpen] = useState(false)

  return (
    <div className={`app ${theme}`}>
      <div className="app-container">
        {/* Navbar */}
        <nav className="navbar">
          <div className="container">
            <div className="logo">
              <div className="logo-icon">
                <span>P</span>
              </div>
              <span className="logo-text">Pumpkin Token</span>
            </div>

            <div className="nav-links desktop-only">
              <a href="#about">About</a>
              <a href="#features">Features</a>
              <a href="#products">Products</a>
              <a
                href="https://thepumpkintoken.org/PumpkinWhitePaper1.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Whitepaper
              </a>
            </div>

            <div className="nav-actions">
              <button className="theme-toggle" onClick={toggleTheme}>
                {theme === "light" ? <FaMoon /> : <FaSun />}
              </button>

              <Button
                variant="primary"
                className="desktop-only buy-button"
                asChild
                href="https://aftermath.finance/trade?from=SUI&to=PUMPKIN"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy $PUMPKIN
              </Button>

              <button
                className="mobile-menu-toggle mobile-only"
                onClick={() => setIsNavOpen(!isNavOpen)}
              >
                {isNavOpen ? "✕" : "☰"}
              </button>
            </div>
          </div>

          {/* Mobile menu */}
          {isNavOpen && (
            <div className="mobile-menu mobile-only">
              <div className="container">
                <a href="#about" onClick={() => setIsNavOpen(false)}>
                  About
                </a>
                <a href="#features" onClick={() => setIsNavOpen(false)}>
                  Features
                </a>
                <a href="#products" onClick={() => setIsNavOpen(false)}>
                  Products
                </a>
                <a
                  href="https://thepumpkintoken.org/PumpkinWhitePaper1.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setIsNavOpen(false)}
                >
                  Whitepaper
                </a>
                <Button
                  variant="primary"
                  className="buy-button"
                  asChild
                  href="https://aftermath.finance/trade?from=SUI&to=PUMPKIN"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setIsNavOpen(false)}
                >
                  Buy $PUMPKIN
                </Button>
              </div>
            </div>
          )}
        </nav>

        {/* Hero Section */}
        <section className="hero-section">
          <div className="container">
            <div className="hero-content">
              <div className="hero-text">
                <Badge variant="outline" className="ecosystem-badge">
                  SUI Ecosystem
                </Badge>
                <h1 className="hero-title">Pumpkin Token</h1>
                <p className="hero-subtitle">
                  A meme token that brings true utility to the SUI ecosystem 💧⚡🌴
                </p>
                <div className="hero-buttons">
                  <Button
                    variant="primary"
                    asChild
                    href="https://aftermath.finance/trade?from=SUI&to=PUMPKIN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Buy $PUMPKIN
                  </Button>
                  <Button
                    variant="outline"
                    asChild
                    href="https://thepumpkintoken.org/PumpkinWhitePaper1.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFileAlt className="icon-left" /> Whitepaper
                  </Button>
                </div>

                <div className="social-links">
                  <a
                    href="https://x.com/thepumpkintoken"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://t.me/+IvaAbB-kQyc3NGQx"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                  >
                    <FaTelegram />
                  </a>
                </div>
              </div>

              <div className="hero-image">
                <div className="logo-large">
                  <div className="logo-glow"></div>
                  <div className="logo-inner">P</div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="about" className="about-section">
          <div className="container">
            <div className="section-header">
              <Badge variant="outline">The Story</Badge>
              <h2 className="section-title">The Heroic Tale of Pumpkin</h2>
              <p className="section-description">
                Pumpkin the Cat, once a humble companion, saved a man's life. When the man was struggling
                with addiction, Pumpkin scratched him, forcing him to the hospital. There, doctors discovered
                life-threatening issues that they were able to address in time, all thanks to Pumpkin.
                Now, Pumpkin Token represents hope, courage, and redemption, just as Pumpkin did for that man.
              </p>
            </div>

            <div className="card-grid three-columns">
              <Card>
                <CardHeader>
                  <CardTitle>Community Driven</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>
                    Built by the community, for the community. Pumpkin Token thrives on the strength of
                    its supporters.
                  </p>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>True Utility</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>
                    Unlike other meme tokens, Pumpkin brings real utility to the SUI ecosystem through
                    innovative products.
                  </p>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Secure & Transparent</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>
                    Built on SUI blockchain with transparent tokenomics and verified smart contracts.
                  </p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>

        {/* Trading Section */}
        <section id="features" className="trading-section">
          <div className="container">
            <div className="section-header">
              <Badge variant="outline">Trading</Badge>
              <h2 className="section-title">Start Trading $PUMPKIN</h2>
              <p className="section-description">
                Pumpkin Token is available on multiple platforms. Choose your preferred method
                to buy, sell, or trade.
              </p>
            </div>

            <div className="card-grid four-columns">
              <Card className="trading-card">
                <CardHeader>
                  <CardTitle>Aftermath</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="aftermath-logo">
                    <img
                      src={require("../src/assets/aftermath-logo.png") || "/placeholder.svg"}
                      alt="Aftermath Logo"
                    />
                  </div>
                  <p>Trade on Aftermath, the premier DEX on SUI.</p>
                </CardContent>
                <CardFooter>
                  <Button
                    variant="outline"
                    className="full-width"
                    asChild
                    href="https://aftermath.finance/trade?from=SUI&to=PUMPKIN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaExchangeAlt className="icon-left" /> Swap Now
                  </Button>
                </CardFooter>
              </Card>

              <Card className="trading-card">
                <CardHeader>
                  <CardTitle>Nexa</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="nexa-logo">
                    <img src="/images/nexa-logo.png" alt="Nexa Logo" />
                  </div>
                  <p>Advanced charting and trading platform for SUI tokens.</p>
                </CardContent>
                <CardFooter>
                  <Button
                    variant="outline"
                    className="full-width"
                    asChild
                    href="https://app.nexa.xyz/trade/0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaChartLine className="icon-left" /> Trade & Chart
                  </Button>
                </CardFooter>
              </Card>

              <Card className="trading-card">
                <CardHeader>
                  <CardTitle>DexScreener</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>View real-time charts and trading data for Pumpkin Token.</p>
                </CardContent>
                <CardFooter>
                  <Button
                    variant="outline"
                    className="full-width"
                    asChild
                    href="https://dexscreener.com/sui/0x3370693cdc29080a1e90f0f927198c751338de7ef7689b505865bf16a0a30461"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaChartLine className="icon-left" /> View Chart
                  </Button>
                </CardFooter>
              </Card>

              <Card className="trading-card">
                <CardHeader>
                  <CardTitle>BlueMove</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>Swap tokens on BlueMove, a popular DEX on SUI.</p>
                </CardContent>
                <CardFooter>
                  <Button
                    variant="outline"
                    className="full-width"
                    asChild
                    href="https://dex.bluemove.net/swap/0x2::sui::SUI/0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaExchangeAlt className="icon-left" /> Swap Now
                  </Button>
                </CardFooter>
              </Card>

              <Card className="trading-card">
                <CardHeader>
                  <CardTitle>Hop.ag</CardTitle>
                </CardHeader>
                <CardContent>
                  <p>Trade with the best rates using Hop.ag aggregator.</p>
                </CardContent>
                <CardFooter>
                  <Button
                    variant="outline"
                    className="full-width"
                    asChild
                    href="https://hop.ag/swap/SUI-0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaExchangeAlt className="icon-left" /> Swap Now
                  </Button>
                </CardFooter>
              </Card>
            </div>

            <div className="contract-view">
              <p>View contract on MovePump</p>
              <Button
                variant="outline"
                asChild
                href="https://movepump.com/token/0x09f1c8f05cb47bbcb61133dd2ef00583720694f41d4f8a61c94467d8f5911a14::pumpkin::PUMPKIN"
                target="_blank"
                rel="noopener noreferrer"
              >
                View Contract
              </Button>
            </div>
          </div>
        </section>

        {/* Products Section */}
        <section id="products" className="products-section">
          <div className="container">
            <div className="section-header">
              <Badge variant="outline">Products</Badge>
              <h2 className="section-title">Innovative Ecosystem</h2>
              <p className="section-description">
                Pumpkin Token powers a suite of advanced products that bring real utility to the SUI blockchain.
              </p>
            </div>

            <div className="card-grid two-columns">
              <Card className="product-card">
                <div className="card-accent"></div>
                <CardHeader>
                  <div className="product-header">
                    <div className="product-icon">
                      <FaRocket />
                    </div>
                    <CardTitle>SUIQuant DexAlgo™</CardTitle>
                  </div>
                  <CardDescription>
                    A revolutionary SUI decentralized exchange algorithm
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="feature-list">
                    <li>
                      <span className="check-icon">✓</span>
                      <span>Secure and autonomous trading</span>
                    </li>
                    <li>
                      <span className="check-icon">✓</span>
                      <span>No reliance on Telegram or external platforms</span>
                    </li>
                    <li>
                      <span className="check-icon">✓</span>
                      <span>Advanced security protocols</span>
                    </li>
                    <li>
                      <span className="check-icon">✓</span>
                      <span>Real-time market analysis</span>
                    </li>
                  </ul>
                </CardContent>
                <CardFooter className="product-footer">
                  <Button
                    variant="primary"
                    asChild
                    href="https://dexalgo2.quantumvoid.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Launch DexAlgo
                  </Button>
                  <Button
                    variant="outline"
                    asChild
                    href="https://dexalgo2.quantumvoid.org/dexalgo-docs/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Documentation
                  </Button>
                </CardFooter>
              </Card>

              <Card className="product-card">
                <div className="card-accent"></div>
                <CardHeader>
                  <div className="product-header">
                    <div className="product-icon">
                      <FaShieldAlt />
                    </div>
                    <CardTitle>AlgoVault™</CardTitle>
                  </div>
                  <CardDescription>
                    An innovative algorithmic vault system
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="feature-list">
                    <li>
                      <span className="check-icon">✓</span>
                      <span>DAO-powered reward system</span>
                    </li>
                    <li>
                      <span className="check-icon">✓</span>
                      <span>Automated yield optimization</span>
                    </li>
                    <li>
                      <span className="check-icon">✓</span>
                      <span>Community-driven governance</span>
                    </li>
                    <li>
                      <span className="check-icon">✓</span>
                      <span>Enhanced security measures</span>
                    </li>
                  </ul>
                </CardContent>
                <CardFooter>
                  <div className="coming-soon">
                    <FaClock className="coming-soon-icon" />
                    <span>Coming Soon</span>
                  </div>
                </CardFooter>
              </Card>
            </div>
          </div>
        </section>

        {/* Powered By Section */}
        <section className="powered-section">
          <div className="container">
            <div className="powered-content">
              <div className="powered-text">
                <p>Powered by</p>
                <a
                  href="https://aftermath.finance/trade?from=SUI&to=PUMPKIN"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="powered-link"
                >
                  Aftermath
                </a>
              </div>

              <Button
                variant="outline"
                asChild
                href="https://aftermath.finance/trade?from=SUI&to=PUMPKIN"
                target="_blank"
                rel="noopener noreferrer"
              >
                Swap on Aftermath
              </Button>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="footer">
          <div className="container">
            <div className="footer-content">
              <div className="footer-logo">
                <div className="logo-icon small">
                  <span>P</span>
                </div>
                <span className="footer-brand">Pumpkin Token</span>
              </div>

              <div className="footer-social">
                <a
                  href="https://x.com/thepumpkintoken"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://t.me/+IvaAbB-kQyc3NGQx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="social-link"
                >
                  <FaTelegram />
                </a>
              </div>

              {/* -----------------------------------------
                  EXPLICIT COPYRIGHT ADDED HERE
              ------------------------------------------ */}
              <div className="footer-copyright">
                &copy; {new Date().getFullYear()} Pumpkin Token. 
                All rights reserved. Unauthorized duplication,
                reproduction, or distribution is strictly prohibited.
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}
